<template>
  <div>
    <div class="dashboard-content my-5">
      <SupportTicket />
    </div>
  </div>
</template>
<script>
import SupportTicket from '../../components/support/SupportTicket.vue';
export default {
    components: {
        SupportTicket
    }
}
</script>
<style lang="scss" scoped>
</style>
