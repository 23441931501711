<template>
  <div class="support-ticket">
    <div class="card support-ticket__card ">
      <h4 class="support-ticket__title mx-3 py-3">Submit support ticket</h4>
      <b-form class="mt-md-4 mt-sm-3 pb-5">
        <b-row class="m-0">
          <b-col md="3">
            <div class="d-flex flex-column">
              <label class="m-0 support-ticket__label" for="subject">Subject</label>
              <span class="support-ticket__description mt-1">Input your issue subject</span>
            </div>
          </b-col>
          <b-col class="" md="6">
            <b-form-input class="py-4 support-ticket__textinput" placeholder="Subject"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="m-0 mt-5">
          <b-col class="" md="3">
            <div class="d-flex flex-column">
              <label class="m-0 support-ticket__label" for="subject">Message</label>
              <span class="support-ticket__description mt-1">Give a detail of the issue</span>
            </div>
          </b-col>
          <b-col class="" md="6">
            <b-form-textarea id="textarea" placeholder="Message" rows="10" class="py-3 support-ticket__textarea">
            </b-form-textarea>
          </b-col>
        </b-row>
        <b-row class="m-0 mt-4  justify-content-center">
          <b-col md="6" sm="12">
            <b-button class="support-ticket__button" variant="primary" block>Submit</b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>
<script>
export default {
  name: "SupportTicket"
}
</script>
<style lang="scss" scoped>
.support-ticket {
  color: $black-tint-3;

  &__card{
  width: 100%;
  border-radius: $size-10;
  // height: 70vh;
}
  

  &__title {
    font-weight: 600;
    font-size: 1.5rem;
    border-bottom: $input-border-bottom;
  }

  ;

  &__label {
    font-weight: 600;
  }

  &__description {
    font-size: $medium-size;

    @include media-breakpoint-down(sm) {
      padding-top: 0.5rem;
    }
  }

  &__textinput::placeholder,
  &__textarea::placeholder {
    color: $black-tint;
  }
}
</style>